import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "menu-item px-3" }
const _hoisted_2 = { class: "card card-flush" }
const _hoisted_3 = { class: "card-body pt-0" }
const _hoisted_4 = {
  key: 0,
  class: "symbol symbol-35px symbol-circle"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "menu-item px-3" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportNotificationForm = _resolveComponent("ReportNotificationForm")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_KtDatatable = _resolveComponent("KtDatatable")!
  const _component_Entities = _resolveComponent("Entities")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_modal, {
      id: "addReportUser",
      name: "Add User ",
      ref: "addReportUserRef"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ReportNotificationForm, {
          "report-id": _ctx.reportId,
          "client-id": _ctx.report?.client?.id,
          onSaved: _ctx.onSaved
        }, null, 8, ["report-id", "client-id", "onSaved"])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_Entities, {
      "enable-filter": false,
      "view-card": false,
      "display-view": false,
      items: _ctx.page.data.total,
      label: "Notification",
      "view-table": true
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_QuickAction, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("a", {
                href: "javascript:void(0);",
                class: "menu-link px-3",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAdd && _ctx.onAdd(...args)))
              }, "Add User")
            ])
          ]),
          _: 1
        })
      ]),
      table: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_KtDatatable, {
              "table-data": _ctx.page.data.data,
              "table-header": _ctx.tableHeader,
              "enable-items-per-page-dropdown": true,
              "current-page": _ctx.filter.paginationPage,
              "rows-per-page": _ctx.filter.paginationSize,
              onItemsPerPageChange: _ctx.handleUpdateSize,
              onCurrentChange: _ctx.handleUpdatePage,
              total: _ctx.page.data.total
            }, {
              "cell-receiver": _withCtx(({ row: view }) => [
                (view.receiver)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_Avatar, {
                        avatar: view?.receiver?.avatar,
                        "user-id": view?.receiver?.id?.toString()
                      }, null, 8, ["avatar", "user-id"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", {
                  onClick: ($event: any) => (_ctx.loadData(view?.user?.id, 'USER'))
                }, _toDisplayString(view?.receiver?.fullName), 9, _hoisted_5)
              ]),
              "cell-actions": _withCtx(({ row: view }) => [
                _createVNode(_component_QuickAction, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("a", {
                        href: "javascript:void(0);",
                        class: "menu-link px-3 text-danger",
                        onClick: ($event: any) => (_ctx.onDelete(view.id))
                      }, "Delete", 8, _hoisted_7)
                    ])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["table-data", "table-header", "current-page", "rows-per-page", "onItemsPerPageChange", "onCurrentChange", "total"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["items"])
  ], 64))
}