
import {defineComponent, ref} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import LogReportNotificationService from "@/core/services/LogReportNotificationService";

export default defineComponent({
  name: "ReportNotificationForm",
  components: {UserListSelect, BaseForm},
  props: {
    clientId: {},
    reportId: {},
  },
  emits: ['saved'],
  setup() {
    const submitting = ref(false);
    const model = ref<any>({userIds: [], reportId: ''})
    return {
      submitting,
      model,
    }
  },
  methods: {
    formSubmit() {
      this.submitting = true;
      this.model.reportId = this.reportId;
      LogReportNotificationService.create(this.model).then(() => {
        this.$emit('saved')
      }).finally(() => {
        this.submitting = false;
        this.model.userIds = []
      })
    }
  }
})
