
import {computed, defineComponent, ref} from 'vue'
import {LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ReportNotificationForm from "@/views/logReport/notification/ReportNotificationForm.vue";
import Swal from "sweetalert2";
import LogReportNotificationService from "@/core/services/LogReportNotificationService";

export default defineComponent({
  name: "LogReportNotifications",
  components: {ReportNotificationForm, BaseModal, QuickAction, Avatar, KtDatatable, Entities},
  props: {
    reportId: {}
  },
  setup(props) {
    const report = computed(() => store.state.LogReportModule.entity);
    const objectFilter = ref({reportId: props.reportId})
    const page = computed(() => store.state.LogReportNotificationModule.page);
    const tableHeader = ref([
      {
        name: "Receiver",
        key: "receiver",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      page,
      report,
      tableHeader,
      ...LoadPanel(),
      ...LoadFilterObjects(Actions.LOAD_LOG_REPORT_NOTIFICATIONS, objectFilter.value, ['document', 'receiver'])
    }
  },
  methods: {
    onAdd() {
      const modal = this.$refs.addReportUserRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      this.paginationLoad()
      const modal = this.$refs.addReportUserRef as typeof BaseModal;
      modal.hideBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await LogReportNotificationService.delete(id).then(() => {
            this.paginationLoad()
          })
        }
      })
    }
  }
})
